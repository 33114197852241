import {StateCreator} from 'zustand';
import {UserSlice} from '../types/types';

const initialState = {
  userEmail: '',
  userRedirectUrl: '',
};

const CreateUserSlice: StateCreator<UserSlice> = set => ({
  ...initialState,
  setUserEmail: (value: string) => set({userEmail: value}),
  setUserRedirectUrl: (value: string) => set({userRedirectUrl: value}),
  reset: () => set(initialState),
});

export default CreateUserSlice;
