import { StateCreator } from 'zustand';
import { CurrencySlice } from '../types/types';

const initialState = {
  currency: { label: '€', value: 'EUR' }, // Default currency
  rate: 1,
};

const CreateCurrencySlice: StateCreator<CurrencySlice> = (set) => ({
  ...initialState,
  setCurrency: (value: CurrencySlice['currency']) => set({ currency: value }),
  setRate: (rate: number) => set({ rate }),
  reset: () => set(initialState),
});

export default CreateCurrencySlice;
