import {StateCreator} from 'zustand';

import ISettings from '../types/types';
import {TransferRouteStop} from '@/api/repositories/types/transferTypes';

const createSettingsSlice: StateCreator<ISettings> = set => ({
  currentProviderTab: 0,
  setCurrentProviderTab: (value: number) => set({currentProviderTab: value}),
  currentArticleTab: 0,
  setCurrentArticleTab: (value: number) => set({currentArticleTab: value}),
  currentTab: 0,
  setCurrentTab: (value: number) => set({currentTab: value}),
  isSidebarOpened: true,
  setIsSideBarOpened: (value: boolean) => set({isSidebarOpened: value}),
  transferRouteStops: [],
  setTransferRouteStops: (value: TransferRouteStop[]) =>
    set({transferRouteStops: value}),
  routesChanged: false,
  setRoutesChanged: (value: boolean) => set({routesChanged: value}),
  scheduleDayOfTheWeek: undefined,
  setScheduleDayOfTheWeek: (value: Date) => set({scheduleDayOfTheWeek: value}),
});

export default createSettingsSlice;
