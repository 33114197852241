import {StateCreator} from 'zustand';
import {ExtrasData, PassengerInfo, PassengersSlice} from '../types/types';

const initialState = {
  passengers: [],
};

const CreatePassengersSlice: StateCreator<PassengersSlice> = set => ({
  ...initialState,
  setPassengers: (id, data, additionalInfo) =>
    set(state => {
      const updatedPassengers = state.passengers.map(passenger =>
        passenger.id === id
          ? {
              ...passenger,
              data: {
                ...passenger.data,
                ...data,
                // Add or update additional phones in the data object
                additionalPhones:
                  data.additionalPhones !== undefined
                    ? data.additionalPhones
                    : passenger.data.additionalPhones || [],
              },
            }
          : passenger
      );

      // If the passenger with the specified id doesn't exist, add it.
      if (!updatedPassengers.some(passenger => passenger.id === id)) {
        updatedPassengers.push({
          id,
          data: {
            ...data,
            isHolder: data.isHolder!,
            name: data.name!,
            surname: data.surname!,
            email: data.email!,
            phone: data.phone! || '',
            dateOfBirth: data.dateOfBirth!,
            startLocation: data.startLocation!,
            endLocation: data.endLocation!,
            extras: data.extras!,
            ageGroupId: data.ageGroupId!,
            ageGroupEnum: data.ageGroupEnum!,
            additionalPhones: data.additionalPhones!,
          },
          additionalInfo: {
            isAdult: additionalInfo.isAdult!,
            number: additionalInfo.number!,
          },
        });
      }

      return {passengers: updatedPassengers};
    }),

  removePassenger: (id: string) =>
    set(state => {
      const updatedPassengers = state.passengers.filter(
        passenger => passenger.id !== id
      );

      return {passengers: updatedPassengers};
    }),

  addExtrasToPassenger: (id: string, extras: ExtrasData[]) =>
    set(state => {
      const updatedPassengers = state.passengers.map(passenger => {
        if (passenger.id === id) {
          const updatedExtras = passenger.data.extras.map(extra => {
            const index = extras.findIndex(
              newExtra =>
                newExtra.extraId === extra.extraId &&
                newExtra.type === extra.type
            );
            if (index !== -1) {
              return extras[index];
            }
            return extra;
          });

          extras.forEach(newExtra => {
            if (
              !updatedExtras.some(
                extra =>
                  newExtra.extraId === extra.extraId &&
                  newExtra.type === extra.type
              )
            ) {
              updatedExtras.push(newExtra);
            }
          });

          return {
            ...passenger,
            data: {
              ...passenger.data,
              extras: updatedExtras,
            },
          };
        }
        return passenger;
      });

      return {passengers: updatedPassengers};
    }),
  reset: () => set(initialState),
});

export default CreatePassengersSlice;
