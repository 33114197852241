import { StateCreator } from 'zustand';
import { LocationPicker } from '../types/types';

const initialState = {
  locationInformation: {
    from: null,
    to: null,
  },
  locationCoordinates: {
    from: null,
    to: null,
  },
};

const CreateLocationPickerSlice: StateCreator<LocationPicker> = (set) => ({
  ...initialState,
  setFromLocationInformation: (value: any) =>
    set((state: any) => ({
      ...state,
      locationInformation: { ...state.locationInformation, from: value },
    })),
  setToLocationInformation: (value: any) =>
    set((state: any) => ({
      ...state,
      locationInformation: { ...state.locationInformation, to: value },
    })),
  setLocationCoordinates: (value: any) => set({ locationCoordinates: value }),
  removeFromPin: () =>
    set((state: any) => ({
      ...state,
      locationInformation: { ...state.locationInformation, from: null },
      locationCoordinates: {
        ...state.locationCoordinates,
        from: null,
      },
    })),
  removeToPin: () =>
    set((state: any) => ({
      ...state,
      locationInformation: { ...state.locationInformation, to: null },
      locationCoordinates: {
        ...state.locationCoordinates,
        to: null,
      },
    })),
  switchPins: () =>
    set((state: any) => ({
      ...state,
      locationInformation: {
        from: state.locationInformation.to,
        to: state.locationInformation.from,
      },
      locationCoordinates: {
        from: state.locationCoordinates.to,
        to: state.locationCoordinates.from,
      },
    })),
  reset: () => set(initialState),
});

export default CreateLocationPickerSlice;
