import {StateCreator} from 'zustand';
import {AvailableVehicle, ManageTransfers, VehicleData} from '../types/types';
import {
  TransferPlan,
  TransferReservation,
  TransferDetails,
} from '@/app/[locale]/(admin)/admin/transferPlanning/manage/content/types';

const CreateManageTransfersSlice: StateCreator<ManageTransfers> = set => ({
  passengers: [],
  setPassengers: (value: TransferReservation[]) => set({passengers: value}),
  vehicles: [
    {
      passengersInVehicle: [],
      route: [],
      isOverCapacity: false,
    },
  ],
  setVehicles: (value: VehicleData[]) => set({vehicles: value}),
  nextStepActive: true,
  setNextStepActive: (value: boolean) => set({nextStepActive: value}),
  activeSegment: 0,
  setActiveSegment: (value: number) => set({activeSegment: value}),
  transferData: [
    {
      id: 1,
      destination: 'Niksic',
      long: 42.7805,
      lat: 18.9562,
      passengerNo: 16,
      reservations: [
        {
          id: 1,
          passengerNo: 5,
          long: 42.777372,
          lat: 18.9528,
          segment: 0,
          passengers: [
            'Marko D.',
            'Stefan T.',
            'Pedja M',
            'Jovan G.',
            'Milos F.',
          ],
          status: 'start',
          leaveOn: 1,
          vehicleAssigned: null,
          leaveLong: 42.532714,
          leaveLat: 19.111201,
        },
        {
          id: 2,
          passengerNo: 2,
          long: 42.775233,
          lat: 18.944728,
          segment: 0,
          passengers: ['Marko D.', 'Stefan T.'],
          status: 'start',
          vehicleAssigned: null,
          leaveOn: 2,
          leaveLong: 42.4304,
          leaveLat: 19.2594,
        },
        {
          id: 3,
          passengerNo: 2,
          long: 42.8476,
          lat: 18.9354,
          segment: 0,
          passengers: ['Strahinja K.', 'Vojin J.'],
          status: 'start',
          leaveOn: 2,
          vehicleAssigned: null,
          leaveLong: 42.4304,
          leaveLat: 19.2594,
        },
      ],
    },
    {
      id: 2,
      destination: 'Danilovgrad',
      long: 42.5538,
      lat: 19.1077,
      passengerNo: 4,
      reservations: [
        {
          id: 3,
          passengerNo: 1,
          segment: 1,
          long: 42.552714,
          lat: 19.101201,
          passengers: ['Marko D.'],
          status: 'start',
          leaveOn: 2,
          vehicleAssigned: null,
          leaveLong: 42.4304,
          leaveLat: 19.2594,
        },
        {
          id: 4,
          passengerNo: 3,
          segment: 1,
          long: 42.550326,
          lat: 19.108926,
          passengers: ['Marko D.', 'Stefan T.', 'Fedja K.'],
          status: 'start',
          leaveOn: 2,
          vehicleAssigned: null,
          leaveLong: 42.4304,
          leaveLat: 19.2594,
        },
      ],
    },
    {
      id: 3,
      destination: 'Podgorica',
      long: 42.4304,
      lat: 19.2594,
      passengerNo: null,
      reservations: null,
    },
  ] as TransferPlan[],
  transferDetails: {} as TransferDetails,
  setTransferDetails: (value: TransferDetails) => set({transferDetails: value}),
  setTransferData: (value: TransferPlan[]) => set({transferData: value}),
  transferDestinations: [] as string[],
  setTransferDestinations: (value: string[]) =>
    set({transferDestinations: value}),
  nextStepBtn: true,
  setNextStepBtn: (value: boolean) => set({nextStepBtn: value}),
  rerenderRoute: true,
  setRerenderRoute: (value: boolean) => set({rerenderRoute: value}),
  deleteSpecificRoute: null,
  setDeleteSpecificRoute: (value: null | number) =>
    set({deleteSpecificRoute: value}),
  availableVehicles: null,
  setAvailableVehicles: value => set({availableVehicles: value}),
  transferLocations: [] as {lat: number; long: number}[][],
  setTransferLocations: (value: {lat: number; long: number}[][]) =>
    set({transferLocations: value}),

  distanceDurationLoading: false,
  setDistanceDurationLoading: (isLoading: boolean) =>
    set({distanceDurationLoading: isLoading}),
  routeId: null,
  setRouteId: (value: number) => set({routeId: value}),
  firstRouteStopId: null ,
  setfirstRouteStopId:(value: number | null | undefined) => set({firstRouteStopId: value})
});

export default CreateManageTransfersSlice;
