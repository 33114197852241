import { StateCreator } from 'zustand';
import { SearchTrackingData } from '../types/types';

const initialState = {
  data: {},
};

const CreateSearchTrackingDataSlice: StateCreator<SearchTrackingData> = (
  set
) => ({
  ...initialState,
  setData: (data: any) => set({ data }),
  reset: () => set(initialState),
});

export default CreateSearchTrackingDataSlice;
