import {StateCreator} from 'zustand';
import {PaymentInfo, StripePaymentDetails} from '../types/types';

const initialState = {
  paymentIntent: '',
  stripePaymentDetails: {} as StripePaymentDetails,
  beginInitialPayment: false,
  redirectUrl: '',
};

const CreatePaymentInfoSlice: StateCreator<PaymentInfo> = set => ({
  ...initialState,
  setPaymentIntent: (value: string) => set({paymentIntent: value}),
  setStripePaymentDetails: (value: StripePaymentDetails) =>
    set({stripePaymentDetails: value}),
  setBeginInitialPayment: (value: boolean) => set({beginInitialPayment: value}),
  setRedirectUrl: (value: string) => set({redirectUrl: value}),
  reset: () => set(initialState),
});

export default CreatePaymentInfoSlice;
