import {StateCreator} from 'zustand';
import {
  InitialPassengerExtras,
  LegacyPassenger,
  PassengerPriceInfo,
  UpdateBooking,
  UserSlice,
} from '../types/types';
import {PassengersExtrasUpdate} from '@/api/repositories/types/extrasUpdateTypes';

const initialState = {
  activeTripStep: 0,
  activeExtrasStep: 0,
  activePassengersStep: 0,
  tripUpdateConfirmation: false,
  legacyPassengers: [] as LegacyPassenger[],
  totalPassengerPrice: 0,
  totalExtrasPrice: 0,
  passengerAvailability: true,
  passengerPriceInfo: {} as PassengerPriceInfo,
  oldExtrasPrice: 0,
  credit: 0,
  pricePerPassenger: 0,
  initialPassengerExtras: [] as PassengersExtrasUpdate[],
  isReservationError: false,
};

const CreateUpdateBookingSlice: StateCreator<UpdateBooking> = set => ({
  ...initialState,
  setActiveTripStep: (value: number) => set({activeTripStep: value}),
  setActiveExtrasStep: (value: number) => set({activeExtrasStep: value}),
  setActivePassengersStep: (value: number) =>
    set({activePassengersStep: value}),
  setTripUpdateConfirmation: (value: boolean) =>
    set({tripUpdateConfirmation: value}),
  setLegacyPassengers: (value: LegacyPassenger[]) =>
    set({legacyPassengers: value}),
  setTotalPassengerPrice: (value: number) => set({totalPassengerPrice: value}),
  setTotalExtrasPrice: (value: number) => set({totalExtrasPrice: value}),
  setPassengerAvailability: (value: boolean) =>
    set({passengerAvailability: value}),
  setPassengerPriceInfo: (value: PassengerPriceInfo) =>
    set({passengerPriceInfo: value}),
  setOldExtrasPrice: (value: number) => set({oldExtrasPrice: value}),
  setCredit: (value: number) => set({credit: value}),
  setPricePerPassenger: (value: number) => set({pricePerPassenger: value}),
  setInitialPassengerExtras: (value: PassengersExtrasUpdate[]) =>
    set({initialPassengerExtras: value}),
  setReservationError: (value: boolean) => set({isReservationError: value}),
  reset: () => set(initialState),
});

export default CreateUpdateBookingSlice;
