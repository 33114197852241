import { StateCreator } from 'zustand';
import { SearchTransfersData } from '../types/types';
import { Transfers } from '@/api/repositories/types/search-transfer-types';

const initialState = {
  transfers: { outboundTransferList: [], returnTransferList: [] },
  isLoadingTransfers: false,
};

const CreateSearchTransfersDataSlice: StateCreator<SearchTransfersData> = (
  set
) => ({
  ...initialState,
  setTransfers: (transfers: Transfers) => set({ transfers }),
  setIsLoadingTransfers: (isLoadingTransfers: boolean) =>
    set({ isLoadingTransfers }),
  reset: () => set(initialState),
});

export default CreateSearchTransfersDataSlice;
