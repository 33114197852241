import { StateCreator } from 'zustand';
import { BookingTimer } from '../types/types';

const initialState = {
  timerValue: Number.MAX_SAFE_INTEGER,
  showTimerExpDialog: true,
  timerExtended: false,
  newTimeStamp: '',
  timerDuration: Number.MAX_SAFE_INTEGER, // timer duration is by default 30, until the data is fetched to set the new timer duration
};

const CreateBookingTimerSlice: StateCreator<BookingTimer> = (set) => ({
  ...initialState,
  setTimerValue: (value: number) => set({ timerValue: value }),
  setShowTimerExpDialog: (value: boolean) => set({ showTimerExpDialog: value }),
  setTimerExtended: (value: boolean) => set({ timerExtended: value }),
  setNewTimeStamp: (value: string) => set({ newTimeStamp: value }),
  setTimerDuration: (value: number) => set({ timerDuration: value }),
  reset: () => set(initialState),
});

export default CreateBookingTimerSlice;
