export const enum AGE_GROUP_ENUM {
  Adult = 0,
  Children = 1,
}

export const enum PAYMENT_TYPE_ENUM {
  Cash = 0,
  Card = 1,
}

export const enum PAYMENT_DISCOUNT_ENUM {
  Discount = 0,
  Surcharge = 1,
}

export const enum EXTRA_TYPE_ENUM {
  Quantitative = 0,
  Selective = 1,
}
