import {
  TempReservation,
  AvailableExtraCats,
  FormatedPaymentType,
  PreviousStep,
} from './../../api/repositories/types/tempReservationTypes';
import { StateCreator } from 'zustand';
import { PriceSummary, SearchResult } from '../types/types';
import { searchResultTypes } from '@/app/[locale]/(web)/component/app-search-result/constants/constants';

const initialState = {
  headerTitle: '',
  footerOutboundPrice: 0,
  footerReturnPrice: 0,
  totalFooterPrice: 0,
  activeStep: -1,
  footerButtonLabel: 'Book trip',
  type: searchResultTypes.loading,
  isAirpotTransfer: false,
  isReturn: false,
  isOutboundTrip: false,
  outboundFlightNo: '',
  returnFlightNo: '',
  hasSummary: true,
  showSummary: false,
  isPhoneVerified: false,
  showVerifyPhone: false,
  showConfirmExit: false,
  tempReservationCreated: false,
  tempReservation: {} as TempReservation,
  availableExtras: [] as AvailableExtraCats[],
  availablePaymentMethods: [] as FormatedPaymentType[],
  appliedDiscountNames: [] as string[],
  paymentType: '',
  priceSummary: null,
  totalExtrasPrice: 0,
  totalPriceBeforePayment: 0,
  totalCreditAmount: 0,
  totalTripPrice: 0,
  refund: false,
  loadingPayment: false,
  isFullTransfer: false,
  isReservationError: false,
  previousSteps: [] as PreviousStep[],
  isLoginInProgress: false,
  tempReservationNote: null,
  isOutboundFlightNumberManuallyEntered: false,
  isReturnFlightNumberManuallyEntered: false,
};

const CreateSearchResultSlice: StateCreator<SearchResult> = (set) => ({
  ...initialState,
  setHeaderTitle: (value: string) => set({ headerTitle: value }),
  setFooterOutboundPrice: (value: number) => set({ footerOutboundPrice: value }),
  setFooterReturnPrice: (value: number) => set({ footerReturnPrice: value }),
  setTotalFooterPrice: (value: number) => set({ totalFooterPrice: value }),
  setActiveStep: (value: number) => set({ activeStep: value }),
  setFooterButtonLabel: (value: string) => set({ footerButtonLabel: value }),
  setType: (value: string) => set({ type: value }),
  setIsAirportTransfer: (value: boolean) => set({ isAirpotTransfer: value }),
  setIsReturn: (value: boolean) => set({ isReturn: value }),
  setIsOutboundTrip: (value: boolean) => set({ isOutboundTrip: value }),
  setOutboundFlightNo: (value: string) => set({ outboundFlightNo: value }),
  setReturnFlightNo: (value: string) => set({ returnFlightNo: value }),
  setHasSummary: (value: boolean) => set({ hasSummary: value }),
  setShowSummary: (value: boolean) => set({ showSummary: value }),
  setIsPhoneVerified: (value: boolean) => set({ isPhoneVerified: value }),
  setShowVerifyPhone: (value: boolean) => set({ showVerifyPhone: value }),
  setShowConfirmExit: (value: boolean) => set({ showConfirmExit: value }),
  setTempReservationCreated: (value: boolean) => set({ tempReservationCreated: value }),
  setTempReservation: (value: TempReservation) => set({ tempReservation: value }),
  setAvailableExtras: (value: AvailableExtraCats[]) => set({ availableExtras: value }),
  setAvailablePaymentMethods: (value: FormatedPaymentType[]) =>
    set({ availablePaymentMethods: value }),
  setPaymentType: (value: string) => set({ paymentType: value }),
  setPriceSummary: (value: PriceSummary) => {
    set({ priceSummary: value });
  },
  setTotalExtrasPrice: (value: number) => set({ totalExtrasPrice: value }),
  setTotalPriceBeforePayment: (value: number) => set({ totalPriceBeforePayment: value }),
  setTotalCreditAmount: (value: number) => set({ totalCreditAmount: value }),
  setTotalTripPrice: (value: number) => set({ totalTripPrice: value }),
  setAppliedDiscountNames: (value: string[]) => set({ appliedDiscountNames: value }),
  setRefund: (value: boolean) => set({ refund: value }),
  setLoadingPayment: (value: boolean) => set({ loadingPayment: value }),
  setIsFullTransfer: (value: boolean) => set({ isFullTransfer: value }),
  setReservationError: (value: boolean) => set({ isReservationError: value }),
  setPreviousSteps: (value: PreviousStep[]) => set({ previousSteps: value }),
  setIsLoginInProgress: (value: boolean) => set({ isLoginInProgress: value }),
  setTempReservationNote: (value: string | null) => set({ tempReservationNote: value }),
  setIsOutboundFlightNumberManuallyEntered: (value: boolean) =>
    set({ isOutboundFlightNumberManuallyEntered: value }),
  setIsReturnFlightNumberManuallyEntered: (value: boolean) =>
    set({ isReturnFlightNumberManuallyEntered: value }),
  reset: () => set(initialState),
});

export default CreateSearchResultSlice;
