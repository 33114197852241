import {StateCreator} from 'zustand';
import {SelectedTicket, TempTicketSelection} from '../types/types';

const initialState = {
  outboundBreakInfo: '',
  outboundDepartureTime: '',
  outboundDepartureLocation: '',
  outboundArrivalTime: '',
  outboundArrivalLocation: '',
  outboundPrice: 0,
  returnBreakInfo: '',
  returnDepartureTime: '',
  returnDepartureLocation: '',
  returnArrivalTime: '',
  returnArrivalLocation: '',
  returnPrice: 0,
  isOutboundTicketSelected: false,
  isReturnTicketSelected: false,
  outboundTicketId: -1,
  returnTicketId: -1,
  outboundReservation: {} as TempTicketSelection,
  returnReservation: {} as TempTicketSelection,
};

const CreateSelectedTicketSlice: StateCreator<SelectedTicket> = set => ({
  ...initialState,
  setOutboundTicketId: (value: number) => set({outboundTicketId: value}),
  setReturnTicketId: (value: number) => set({returnTicketId: value}),
  setIsReturnTicketSelected: (value: boolean) =>
    set({isReturnTicketSelected: value}),
  setIsOutboundTicketSelected: (value: boolean) =>
    set({isOutboundTicketSelected: value}),
  setOutboundBreakInfo: (value: string) => set({outboundBreakInfo: value}),
  setOutboundDepartureTime: (value: string) =>
    set({outboundDepartureTime: value}),
  setOutboundDepartureLocation: (value: string) =>
    set({outboundDepartureLocation: value}),
  setOutboundArrivalTime: (value: string) => set({outboundArrivalTime: value}),
  setOutboundArrivalLocation: (value: string) =>
    set({outboundArrivalLocation: value}),
  setOutboundPrice: (value: number) => set({outboundPrice: value}),
  setReturnBreakInfo: (value: string) => set({returnBreakInfo: value}),
  setReturnDepartureTime: (value: string) => set({returnDepartureTime: value}),
  setReturnDepartureLocation: (value: string) =>
    set({returnDepartureLocation: value}),
  setReturnArrivalTime: (value: string) => set({returnArrivalTime: value}),
  setReturnArrivalLocation: (value: string) =>
    set({returnArrivalLocation: value}),
  setReturnPrice: (value: number) => set({returnPrice: value}),
  setOutboundReservation: (value: TempTicketSelection) =>
    set({outboundReservation: value}),
  setReturnReservation: (value: TempTicketSelection) =>
    set({returnReservation: value}),
  reset: () => set(initialState),
});

export default CreateSelectedTicketSlice;
