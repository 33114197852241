import { StateCreator } from 'zustand';
import { PassengerCategoryInfo, SearchRide } from '../types/types';
import { FlatDestinationDetails } from '@/api/repositories/types/searchRideTypes';
import { AGE_GROUP_ENUM } from '@/utils/enums';

const passengerInfoInitialState: PassengerCategoryInfo[] = [
  {
    ageGroupEnum: AGE_GROUP_ENUM.Adult,
    number: 1,
  },
  {
    ageGroupEnum: AGE_GROUP_ENUM.Children,
    number: 0,
  },
];

const initialState = {
  fromDestination: '',
  toDestination: '',
  selectFromDestination: {} as FlatDestinationDetails,
  selectToDestination: {} as FlatDestinationDetails,
  passengerInfo: passengerInfoInitialState as PassengerCategoryInfo[],
  passengerNumber: 1,
  adultNumber: 1,
  childrenNumber: 0,
  isAssistanceNeeded: false,
  addReturnTrip: false,
  isDepartingFrom: true,
  isDepartingFromReturn: true,
  arrivingAt: '',
  arrivingDate: null,
  // arrivingDate: startOfDay(new Date()),
  arrivingTime: '',
  returnAt: '',
  returnDate: null,
  // returnDate: startOfDay(new Date()),
  returnTime: '',
  isAirportTransfer: false,
  showSearchRideOnResults: false,
  canFetchRecentSearches: false,
};

const CreateSearchRideSlice: StateCreator<SearchRide> = (set) => ({
  ...initialState,
  setFromDestination: (value: string) => set({ fromDestination: value }),
  setToDestination: (value: string) => set({ toDestination: value }),
  setSelectFromDestination: (value: FlatDestinationDetails) =>
    set({ selectFromDestination: value }),
  setSelectToDestination: (value: FlatDestinationDetails) => set({ selectToDestination: value }),
  setPassengerInfo: (value: PassengerCategoryInfo[]) => set({ passengerInfo: value }),
  setPassengerNumber: (value: number) => set({ passengerNumber: value }),
  setAdultNumber: (value: number) => set({ adultNumber: value }),
  setChildrenNumber: (value: number) => set({ childrenNumber: value }),
  setIsAssistanceNeeded: (value: boolean) => set({ isAssistanceNeeded: value }),
  setAddReturnTrip: (value: boolean) => set({ addReturnTrip: value }),
  setIsDepartingFrom: (value: boolean | null) => set({ isDepartingFrom: value }),
  setIsDepartingFromReturn: (value: boolean | null) => set({ isDepartingFromReturn: value }),
  setArrivingAt: (value: string) => set({ arrivingAt: value }),
  setArrivingDate: (value: Date) => set({ arrivingDate: value }),
  setArrivingTime: (value: string) => set({ arrivingTime: value }),
  setReturnAt: (value: string) => set({ returnAt: value }),
  setReturnDate: (value: Date) => set({ returnDate: value }),
  setReturnTime: (value: string) => set({ returnTime: value }),
  setIsAirportTransfer: (value: boolean) => set({ isAirportTransfer: value }),
  setShowSearchRideOnResults: (value: boolean) => set({ showSearchRideOnResults: value }),
  setCanFetchRecentSearches: (value: boolean) => set({ canFetchRecentSearches: value }),
  reset: () => set(initialState),
});

export default CreateSearchRideSlice;
